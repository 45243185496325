<template>
    <div>
        <p class="selection_title">{{ title }}</p>
        <ion-select
            name="tipologia"
            interface="action-sheet"
            cancel-text="Annulla"
            ok-text="Conferma"
            :value="selected"
            :placeholder="placeholder"
            @ionChange="onChange($event.target.value)"
        >
            <ion-select-option
                v-for="item in items"
                :key="item.magazzini_id || item.movimenti_causali_id || item.id"
                :value="item.magazzini_id || item.movimenti_causali_id || item.id"
            >
                {{ item.magazzini_titolo || item.movimenti_causali_nome || item.tipo }}
            </ion-select-option>
        </ion-select>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { IonSelect, IonSelectOption } from "@ionic/vue";

export default defineComponent({
    name: "SelectMagazzino",
    props: {
        title: String,
        items: Array,
        selected: [String, Number],
        placeholder: String,
    },
    emits: ["change"],
    components: {
        IonSelect,
        IonSelectOption,
    },
    setup(props, { emit }) {
        const onChange = (value) => {
            emit("change", value);
        };

        return {
            onChange,
        };
    },
});
</script>

<style scoped>
.selection_title {
    margin: 4px;
    font-size: 14px;
    font-weight: 500;
}
</style>
