<template>
    <div>
        <div class="scanner-container">
            <div v-show="!isLoading" style="position: relative; max-width: 100%; height: 300px; display: flex; justify-content: center; align-items: center;">
                <video poster="data:image/gif,AAAA" ref="scanner"></video>
                <!-- <div class="overlay-element"></div> -->
                <div class="laser"></div>
            </div>
        </div>
        <div class="actions_container">
            <button @click="startScanning" :disabled="!isLoading">Scansiona</button>
            <!-- <button @click="resumeScanning" :disabled="!isLoading">Riprendi</button> -->
            <button @click="stopScanning" :disabled="isLoading">Stop</button>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { BrowserMultiFormatReader, Exception } from "@zxing/library";

export default {
    name: "BarcodeCustom",
    setup(_, { emit, expose }) {
        const isLoading = ref(true);
        const scanner = ref(null);
        const codeReader = new BrowserMultiFormatReader();
        const isMediaStreamAPISupported = navigator && navigator.mediaDevices && "enumerateDevices" in navigator.mediaDevices;

        const stopScanning = () => {
            //Aggiungo controllo per evitare errori al cambio pagina in Magazzino
            if(codeReader && codeReader.stream) {
                isLoading.value = true;
                codeReader.stream.getTracks().forEach((track) => {
                    track.stop();
                    console.log(track);
                    track.enabled = false;
                });
                codeReader.reset();
            }
        };

        const startScanning = () => {
            codeReader.decodeFromVideoDevice(null, scanner.value, (result, err) => {
                if (result) {
                    emit("decode", result.text);
                    stopScanning();
                    setTimeout(() => {
                        startScanning();
                    }, 2000);
                } else if (err && !(err instanceof Exception)) {
                    emit("error", err);
                } else {
                    emit("notFound");
                }
            });
        };

        const resumeScanning = () => {
            if (scanner.value) {
                startScanning();
            }
        };

        onMounted(() => {
            if (!isMediaStreamAPISupported) {
                throw new Exception("Media Stream API is not supported");
            }

            //startScanning();
            scanner.value.oncanplay = () => {
                isLoading.value = false;
                emit("loaded");
            };
        });

        onBeforeUnmount(() => {
            stopScanning();
        });

        expose({
            stopScanning,
            codeReader
        });

        return {
            isLoading,
            scanner,
            startScanning,
            resumeScanning,
            stopScanning,
        };
    },
};
</script>

<style scoped>
video {
    max-width: 100%;
    height: 300px;
}
.scanner-container {
    width: 100%;
    height: 300px;
    background-color: #e5e5e5;
    border-radius: 4px;
}

/* .overlay-element {
    position: absolute;
    top: 0;
    width: 100%;
    height: 99%;
    background: rgba(30, 30, 30, 0.5);

    -webkit-clip-path: polygon(2% 2%, 2% 98%, 18% 98%, 18% 18%, 82% 18%, 82% 82%, 18% 82%, 18% 98%, 98% 98%, 98% 2%);
    clip-path: polygon(2% 2%, 2% 98%, 18% 98%, 18% 18%, 82% 18%, 82% 82%, 18% 82%, 18% 98%, 98% 98%, 98% 2%);
} */

.laser {
    width: 100%;
    background-color: tomato;
    height: 1px;
    position: absolute;
    top: 30%;
    z-index: 2;
    box-shadow: 0 0 4px red;
    -webkit-animation: scanning 2s infinite;
    animation: scanning 2s infinite;
}
@-webkit-keyframes scanning {
    50% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }
}
@keyframes scanning {
    50% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }
}

.actions_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.actions_container button {
    background-color: transparent;
    color: #086fa3;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
}
.actions_container button:disabled {
    color: #086fa3;
    font-weight: bold;
    opacity: 0.25;
}
</style>
